import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AddGuide.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import SERVER_URL from "../ServerUrl";
import Tags from "./Tags";

const EditGuide = (props) => {
	var { ID } = useParams();
	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");
	const [nsfw, setNsfw] = useState();
	const [credits, setCredits] = useState("");
	const [tags, setTags] = useState([]);

	const [errorMessage, setErrorMessage] = useState("");
	const [response, setResponse] = useState("");
	const [responseClass, setResponseClass] = useState("");

	const { username } = useContext(UserContext);

	useEffect(async () => {
		const req = await fetch(SERVER_URL + "/api/guides/" + ID, {
			headers: { "x-access-token": localStorage.getItem("token") },
		});
		let data = await req.json();
		if (data.status === "ok") {
			//access the data and then set it as default value for the usestates
			data = data.data;
			setTitle(data.title);
			setLink(data.link);
			setNsfw(data.nsfw);
			setTags(data.tags);
			setCredits(data.credits);
		} else {
			setResponseClass("bg-danger");
			setResponse("Invalid ID");
			setTimeout(() => {
				navigate("/guides");
			}, 500);
		}
	}, []);

	const navigate = useNavigate();

	async function guideHandler(event) {
		event.preventDefault();

		//CREATE PUT REQ IN BACKEND
		// const ID = '625f0617124c3ac0a61771e3';
		const data = await fetch(SERVER_URL + "/api/guides/" + ID, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ title, link, nsfw, credits, tags, username }),
		}).then((data) => {
			if (data.status === 200) {
				setErrorMessage("");
				setResponseClass("bg-success");
				setResponse("Guide Edited!");
				setTimeout(() => {
					navigate("/guides");
				}, 500);
			}
			// todo add error properly for guide already exist
			else {
				setErrorMessage("Guide already exist!");
			}
		});
		// const data = await response.json();
	}

	return (
		<div className="p- d-flex flex-column align-items-center">
			<h1 className="login-header mt-2">
				Edit <span style={{ color: "#E78EA9" }}>Guide</span>
			</h1>
			<div>
				<form
					className="login-form"
					onSubmit={guideHandler}
					//also show a message saying link added
					// onSubmit={() => navigate('/guides')}
				>
					<div className="user-box">
						<input
							className="input-text"
							id="guideName"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required="true"
						/>
						<label>Guide Title</label>
					</div>
					<div className="user-box">
						<input
							className="input-text"
							id="guideLink"
							type="text"
							value={link}
							onChange={(e) => setLink(e.target.value)}
							required="true"
						/>
						<label>Guide Link</label>
					</div>
					<div className="user-box ">
						<input
							className="input-text"
							id="credits"
							type="text"
							value={credits}
							onChange={(e) => setCredits(e.target.value)}
						/>
						<label className="">Credits</label>
					</div>
					<Tags
						tags={tags}
						setTags={setTags}
						setResponse={setResponse}
						setResponseClass={setResponseClass}
					/>
					<div className="d-flex justify-content-start align-items-center">
						<input
							className="me-1"
							type="checkbox"
							name="nsfw"
							checked={nsfw ? "checked" : ""}
							onChange={(e) => setNsfw(e.target.checked)}
						/>{" "}
						NSFW
					</div>
					<p className="mt-0 mb-2 text-danger">{errorMessage}</p>{" "}
					<input
						className="d-block py-2 submit-btn"
						value="Submit"
						type="submit"
					/>
				</form>
			</div>
			<div className="d-flex justify-content-center">
				{response && (
					<div
						style={{
							position: "fixed",
							bottom: "2vh",
							display: "flex",
							flex: 1,
							width: "20vw",
							padding: "0.25rem 0.5rem ",
							borderRadius: "5px",
							justifyContent: "center",
						}}
						className={responseClass}
					>
						{response}
					</div>
				)}
			</div>
		</div>
	);
};

export default EditGuide;
