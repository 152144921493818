import { useState } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Resources from "./components/Resources";
import Home from "./components/Home";
import About from "./components/About";
import Backup from "./components/Backup";
import Error404 from "./components/Error404";
import Guides from "./components/Guides";
import AddGuide from "./components/AddGuide";
import Login from "./components/Login";
import User from "./components/User";
import EditGuide from "./components/EditGuide";
import { UserContext } from "./components/UserContext";
import React from "react";
import jwt from "jsonwebtoken";

function App() {
	const token = localStorage.getItem("token");
	if (token) {
		let user = jwt.decode(token);
		if (user) {
			var initialUsername = user.username;
			var initialIsAdmin = user.admin;
		}
	}
	const [username, setUsername] = useState(initialUsername);
	const [isAdmin, setIsAdmin] = useState(initialIsAdmin);
	return (
		<BrowserRouter>
			<UserContext.Provider
				value={{ username, setUsername, isAdmin, setIsAdmin }}
			>
				<NavigationBar />

				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/resource/:resource" element={<Resources />} />
					<Route path="/backup" element={<Backup />} />
					<Route path="/about" element={<About />} />
					<Route path="/guides/add" element={<AddGuide />} />
					<Route path="/guides/edit/:ID" element={<EditGuide />} />
					<Route path="/guides" element={<Guides />} />
					<Route path="/login" element={<Login />} />
					<Route path="/user" element={<User />} />
					<Route path="*" element={<Error404 />} />
					{/* add /discord reroute to discord invite link */}
				</Routes>
			</UserContext.Provider>
		</BrowserRouter>
	);
}

export default App;
