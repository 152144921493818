import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import GuideItemsGenerator from "./GuideItemsGenerator";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";
import SERVER_URL from "../ServerUrl";

const Guides = () => {
	const [noResultMessage, setNoResultMessage] = useState("Loading...");
	const [response, setResponse] = useState("");
	const [responseClass, setResponseClass] = useState("");
	const { username, isAdmin } = useContext(UserContext);
	const navigate = useNavigate();

	const addGuideHandler = () => {
		if (!username) {
			setResponseClass("bg-danger");
			setResponse("Login to add Guides!");
			setTimeout(() => setResponse(), 500);
		} else navigate("/guides/add");
	};
	//storing search query
	const [inputText, setInputText] = useState("");

	//for flex-direction
	const [flexDir, setFlexDir] = useState("");

	//toggling on and off the search bar, and the x,search icons
	const [inputToggle, setInputToggle] = useState("none");
	const [guideList, setGuideList] = useState();
	//ref for input focus
	const inputElement = useRef(null);
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, [inputToggle]);

	useEffect(() => {
		fetch(SERVER_URL + "/api/guides/all")
			.then((res) => res.json())
			.then((data) => setGuideList(data))
			.catch((err) => {
				console.log(err);
				setNoResultMessage("No search results found!");
			});
	}, []);

	const toggleSearch = () => {
		if (inputToggle === "none") {
			setInputToggle("flex");
			setFlexDir("flex-column");
		} else setInputToggle("none");
	};

	const toggleClose = () => {
		setInputText("");
		if (inputToggle === "flex") {
			setInputToggle("none");
			setFlexDir("");
		} else setInputToggle("flex");
	};

	let inputHandler = (e) => {
		var lowerCase = e.target.value;
		setInputText(lowerCase);
	};

	const filterData = (data) => {
		if (data) {
			const filter_data = data.filter((el) => {
				if (inputText === "") {
					return el;
				} else {
					return el.title.toLowerCase().includes(inputText.toLowerCase());
				}
			});
			return filter_data;
		}
	};

	const classes = "justify-content-between d-flex flex-sm-row " + flexDir;

	return (
		<div className="p-4">
			<div className={classes}>
				<p
					onClick={() => setInputText("")}
					className="text-capitalize mb-0"
					style={{ fontSize: "1.9rem", display: "inline" }}
				>
					Guides
				</p>
				<div className="d-flex ms-sm-auto align-items-center pe-4">
					<div className="search d-flex align-items-center ps-4">
						{/* todo: add hover change icon to pointer */}
						<input
							type="text"
							value={inputText}
							ref={inputElement}
							onChange={inputHandler}
							autoFocus
							placeholder="Search"
							style={{ display: inputToggle }}
						/>
						{/* search button */}
						{inputToggle === "none" && (
							<div onClick={toggleSearch}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									></path>
								</svg>
							</div>
						)}

						{/* close button  */}
						{inputToggle === "flex" && (
							<div className="ps-2" onClick={toggleClose}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									// class="h-18 w-18"
									fill="none"
									width="25"
									height="25"
									viewBox="0 0 14 14"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1"
										d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
									/>
								</svg>
							</div>
						)}
					</div>
					{/* plus icon  */}
					<div onClick={addGuideHandler} className="ms-2" style={{}}>
						<svg
							width="25"
							height="25"
							fill="none"
							stroke="white"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M12 6v6m0 0v6m0-6h6m-6 0H6"
							></path>
						</svg>
					</div>
				</div>
			</div>

			<ul>
				<GuideItemsGenerator
					data={filterData(guideList)}
					updateData={setGuideList}
					noResultMessage={noResultMessage}
				/>
			</ul>

			<div className="d-flex justify-content-center">
				{response && (
					<div
						style={{
							position: "fixed",
							bottom: "2vh",
							display: "flex",
							flex: 1,
							minWidth: "10rem",
							width: "20vw",
							padding: "0.25rem 0.5rem ",
							borderRadius: "5px",
							justifyContent: "center",
						}}
						className={responseClass}
					>
						{response}
					</div>
				)}
			</div>
		</div>
	);
};

export default Guides;
