import React from "react";

import { Link } from "react-router-dom";
const Home = () => {
	return (
		<div className="px-4 pt-4 ">
			<div className="d-lg-flex justify-content-center justify-content-lg-evenly">
				<div className="my-auto">
					<div className="d-flex justify-content-sm-center">
						<h1
							className=""
							style={{ fontSize: "3.2rem", fontFamily: "poppins" }}
						>
							Hey 👋
						</h1>
					</div>

					<div className="d-flex justify-content-center align-items-center flex-column">
						<p
							className="col col-lg-10 "
							style={{ fontSize: "1.4rem", fontFamily: "courier" }}
						>
							Most of the people new to FreeMediaHeckYeah can find our massive
							wiki overwhelming when taking their first look at it. So the
							members of r/FMHY thought that we should make a curated list of
							common sites that we use, one that's much shorter and not an
							entire list of every site we've aggregated over the years, making
							it much easier to navigate 💖 It'll be some underrated sites, ones
							that we use everyday, and guides that may come in handy for a lot
							of people!
						</p>
						<p
							style={{ fontSize: "1.2rem" }}
							className="text-center pt-2 mt-0 pt-xl-4 "
						>
							Check out our <Link to="/about">Vision</Link> & get started with
							our
							<span
								// style={{backgroundColor: '#00bdec', color: 'white'}}

								className="d-inline "
							>
								<a href="https://rentry.org/Piracy-BG">
									{" "}
									Beginners Guide to Piracy
								</a>
							</span>
						</p>

						<div className="d-flex justify-content-center mb-3">
							<a
								style={{
									backgroundColor: "rgb(31, 78, 113)",

									borderRadius: "5px",

									fontSize: "1.1rem",

									color: "white",
								}}
								className="px-2 py-2"
								href=" https://revolt.fmhy.cf"
							>
								Join the Revolt Server!{" "}
								<a href="https://www.reddit.com/r/FREEMEDIAHECKYEAH/comments/uto5vw/revolt_server/">
									(Instructions)
								</a>
							</a>
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-center">
					<img
						className="container-fluid "
						style={{ maxHeight: "85vh", width: "auto", maxWidth: "100vw" }}
						alt="pirate"
						src="../../assets/pirate.png"
					/>
				</div>
			</div>

			<p
				className="text-center mb-1 mt-3 mt-lg-0 "
				style={{ fontSize: "1.2rem" }}
			></p>
		</div>
	);
};

export default Home;
