import React, { useState, useContext, useEffect } from "react";
import "./AddGuide.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import SERVER_URL from "../ServerUrl";
import Tags from "./Tags";

const AddGuide = () => {
	const { username } = useContext(UserContext);
	const navigate = useNavigate();

	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");
	const [nsfw, setNsfw] = useState("");
	const [credits, setCredits] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [response, setResponse] = useState("");
	const [responseClass, setResponseClass] = useState("");
	const [tags, setTags] = useState([]);

	useEffect(async () => {
		if (!username) {
			setResponseClass("bg-danger");
			setResponse("Login inorder to add Guides!");
			setTimeout(() => {
				navigate("/guides");
			}, 500);
		}
	}, []);

	async function guideHandler(event) {
		event.preventDefault();

		const data = await fetch(SERVER_URL + "/api/guides/new", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ title, link, nsfw, username, tags, credits }),
		});
		// const data = await response.json();

		if (data.status === 200) {
			setErrorMessage("");
			setResponseClass("bg-success");
			setResponse("Guide Added!");
			setTimeout(() => {
				navigate("/guides");
			}, 500);
		} else {
			setErrorMessage("Guide already exist!");
		}
	}

	return (
		<div className="p- d-flex flex-column align-items-center">
			<h1 className="login-header mt-2">
				Add <span style={{ color: "#E78EA9" }}>Guide</span>
			</h1>
			<div>
				<form
					className="login-form"
					onSubmit={guideHandler}
					//also show a message saying link added
					// onSubmit={() => navigate('/guides')}
				>
					<div className="user-box ">
						<input
							className="input-text"
							id="guideName"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required="true"
						/>
						<label className="">Guide Title</label>
					</div>
					<div className="user-box">
						<input
							className="input-text"
							id="guideLink"
							type="text"
							value={link}
							onChange={(e) => setLink(e.target.value)}
							required="true"
						/>
						<label className="">Guide Link</label>
					</div>
					<div className="user-box ">
						<input
							className="input-text"
							id="credits"
							type="text"
							value={credits}
							onChange={(e) => setCredits(e.target.value)}
						/>
						<label className="">Credits</label>
					</div>
					<Tags
						tags={tags}
						setTags={setTags}
						setResponse={setResponse}
						setResponseClass={setResponseClass}
					/>
					<div className="d-flex justify-content-start align-items-center">
						<input
							className="me-1"
							type="checkbox"
							name="nsfw"
							value={nsfw}
							onChange={(e) => setNsfw(e.target.checked)}
						/>{" "}
						NSFW
					</div>
					<p className="mt-0 mb-2 text-danger">{errorMessage}</p>{" "}
					<input
						className="d-block py-2 submit-btn"
						value="Submit"
						type="submit"
					/>
				</form>
			</div>
			<div className="d-flex justify-content-center">
				{response && (
					<div
						style={{
							position: "fixed",
							bottom: "2vh",
							display: "flex",
							flex: 1,
							minWidth: "10rem",
							width: "20vw",
							padding: "0.25rem 0.5rem ",
							borderRadius: "5px",
							justifyContent: "center",
						}}
						className={responseClass}
					>
						{response}
					</div>
				)}
			</div>
		</div>
	);
};

export default AddGuide;
