import Backup from "./Backup";

const About = () => {
	return (
		<div className="p-4 ">
			<div className="pt-4">
				<h1 style={{ color: "#EDC988" }} className="">
					Vision
				</h1>
			</div>

			<div className="py-4 d-lg-flex justify-content-evenly align-items-center">
				<div className="col col-lg-4 mb-3 mb-lg-0 d-flex justify-content-center  ">
					<img src="../assets/help.svg" alt="" width={300} />
				</div>
				<p
					className="col col-lg-8 p-3 pt-0"
					style={{
						fontSize: "1.2rem",
						fontFamily: "helvetica",
						color: "#DEEEEA",
						backgroundColor: "#121212",
						borderRadius: "10px",
					}}
				>
					Our goal is to spread free education and entertainment to as many
					people as we can, and fight for the rights of those who feel left
					behind to be happy, educated, and comfortable, despite their economic
					circumstances. We want to show people what the world could look like
					if we started treating everyone as equally deserving of all things
					good. To organize, index and preserve as many sites and as much
					content as we possibly can, both for people now and people in the
					future. We want to do our best to lead by example, and prove that
					putting others before ourselves really does make the world a better
					place.
				</p>
			</div>

			<h1 style={{ color: "#D7385E" }}>Promise</h1>
			<div className="d-lg-flex justify-content-evenly align-items-center">
				<p
					className="col col-lg-8 p-3 pt-0"
					style={{
						fontSize: "1.2rem",
						fontFamily: "helvetica",
						color: "#DEEEEA",
						backgroundColor: "#121212",
						borderRadius: "10px",
					}}
				>
					"I'm not ever going to let this project die. Even if something were to
					happen to me, it would be in good hands thanks to the awesome mods in
					discord. I just wanted to say this because sometimes projects like
					this end up slowly dying, and that isn't going to happen with us.
					Giving as many people as possible access to educational material and
					entertainment does make the world a smarter and happier place. Sharing
					is, always has been, and always will be the right thing to do, and
					until we live in a world that doesn't need to be shown that, we'll be
					here."
					<span
						className="d-block"
						style={{ fontFamily: "helvetica", fontWeight: "600" }}
					>
						- nbatman
					</span>
				</p>
				<div className="d-flex justify-content-center container-fluid">
					<img
						width={300}
						className="col col-lg-4"
						// style={{maxHeight: '100vh', width: 'auto'}}
						alt="logo gif"
						src="../../assets/logo-gif.gif"
					/>
				</div>
			</div>
			<Backup />
		</div>
	);
};

export default About;
