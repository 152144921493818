import React from "react";

const Error404 = () => {
	return (
		<div
			styles={{ height: "100px", width: "auto" }}
			className="d-flex justify-content-center align-items-center"
		>
			<img src="../assets/404.svg" alt="error 404" height={800} />
		</div>
	);
};

export default Error404;
