import React, { useContext } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "./UserContext";

const NavigationBar = () => {
	const { username, setUsername } = useContext(UserContext);
	const isLoggedIn = username ? true : false;

	return (
		<div>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
				<Container>
					<Navbar.Brand href="/">
						<img
							style={{ borderRadius: "16px" }}
							src="../../assets/logo.png"
							alt="logo"
							width="35px"
						/>
					</Navbar.Brand>
					<Navbar.Brand href="/">FMHY</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="ms-auto">
							<NavDropdown
								className="mx-1"
								title="Resources"
								id="collasible-nav-dropdown"
							>
								<NavDropdown.Item href="/resource/adblock-vpn-privacy">
									📛 Adblock / Privacy / Antivirus
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/video">
									📺 Movies / TV / Anime / Sports
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/audio">
									🎵 Music / Podcasts / Radio
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/games">
									🎮 Gaming / Emulation
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/reading">
									📗 Books / Comics / Manga
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/download">
									💾 Downloading
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/torrent">
									🌀 Torrenting
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/edu">
									🧠 Educational
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/tools-misc">
									🔧 Tools
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/misc">
									📂 Miscellaneous
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/android">
									📱 Android / iOS
								</NavDropdown.Item>
								<NavDropdown.Item href="/resource/linux">
									🐧 Linux / MacOS
								</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link className="mx-1" href="/about">
								About
							</Nav.Link>
							{/* <Nav.Link className="mx-1" href="/server">
                Server
              </Nav.Link> */}
							<Nav.Link className="mx-1" href="/guides">
								Guides
							</Nav.Link>
							{!isLoggedIn && (
								<Nav.Link className="mx-1" href="/login">
									Login
								</Nav.Link>
							)}
							{isLoggedIn && (
								<Nav.Link className="mx-1" href="/user">
									Hello {username}
								</Nav.Link>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default NavigationBar;
